import React from "react";
import Navbar from "../components/HomeNav";
import Footer from "../components/Footer";
import {FaInstagram,FaLinkedin } from "react-icons/fa";

import { Link } from "react-router-dom";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
const ContactPage = () => {
    return (
        <>
            <Navbar />
            <div className="flex flex-col items-center bg-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
                {/* Contact Customer Support Section */}
                <div className="text-center max-w-2xl bg-white p-6 rounded-lg mb-8">
                    <h1 className="text-4xl font-bold mb-4 text-gray-900">
                        Contact Customer Support
                    </h1>
                    <p className="text-lg mb-2 text-gray-700">
                        Have a question? Need help?{" "}
                        <a
                            href="mailto:info@phishinstinct.com"
                            className="text-blue-600 border-b border-blue-900"
                        >
                            Submit a request
                        </a>
                        .
                    </p>
                    <p className="text-lg mb-6 text-gray-700">
                        Alternatively,kindly call on the phone number provided below for further
                        support. Currently, we provide support from Monday to
                        Friday.
                    </p>
                    <p className="text-3xl font-bold mb-2 text-gray-700">
                    +91-82083 87709
                    </p>
                </div>

                {/* Join the Community Section */}
                <div className=" bg-neomorphism-gradient p-6 rounded-lg shadow-neomorphism border border-transparent text-center max-w-2xl p-6 rounded-lg mb-8">
                    <h2 className="text-2xl font-bold mb-4 text-gray-900">
                        Join the Newtons Apple Community
                    </h2>
                    <p className="text-lg mb-4 text-gray-700">
                        Want to connect with us? Join us on social media.
                    </p>
                    <div className="flex justify-center space-x-6">
                     
                        <Link to="https://www.instagram.com/newtons.a/?hl=en">
                            <FaInstagram className="text-3xl text-gray-600 hover:text-pink-600" />
                        </Link>
                        <Link to="https://www.linkedin.com/company/newton-s-apple/posts/?feedView=all">
                            <FaLinkedin className="text-3xl text-gray-600 hover:text-blue-400" />
                        </Link>
                     
                    </div>
                </div>

                {/* Contact Info Section */}
                <section className="bg-white dark:bg-white">
                    <div className="container px-6 py-12 mx-auto">
                        <div></div>

                        <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3">
                            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-1">
                                <div>
                                    <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 ">
                                        <FaEnvelope className="w-5 h-5" />
                                    </span>
                                    <h2 className="mt-4 text-base font-medium text-gray-900 dark:text-gray-700">
                                        Email
                                    </h2>

                                    <p className="mt-2 text-sm text-blue-700 dark:text-blue-700">
                                        info@phishinstinct.com
                                    </p>
                                </div>

                                <div>
                                    <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80">
                                        <FaMapMarkerAlt className="w-5 h-5" />
                                    </span>
                                    <h2 className="mt-4 text-base font-medium text-gray-900 dark:text-gray-900">
                                        Office
                                    </h2>

                                    <p className="mt-2 text-sm text-blue-500 dark:text-blue-700">
                                        80/2, Sakal Nagar, Aundh, Pune,
                                        Maharashtra 411007
                                    </p>
                                </div>

                                <div>
                                    <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 ">
                                        <FaPhone className="w-5 h-5" />
                                    </span>
                                    <h2 className="mt-4 text-base font-medium text-gray-700 dark:text-gray-700">
                                        Phone
                                    </h2>

                                    <p className="mt-2 text-sm text-blue-500 dark:text-blue-700">
                                        {" "}
                                        +91-82083 87709
                                    </p>
                                </div>
                            </div>

                            <div className="overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    title="map"
                                    marginHeight="0"
                                    marginWidth="0"
                                    scrolling="no"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.5935416998313!2d73.81523607585993!3d18.54726106835708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1d65e7cb88f%3A0x6e60e97f1c472bf7!2sNewtons%20Apple!5e0!3m2!1sen!2sin!4v1723883051628!5m2!1sen!2sin"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default ContactPage;
