import React, { useState } from 'react';
import Navbar from './HomeNav';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { FaCalendarAlt, FaChartLine, FaFileAlt, FaBook, FaPhone, FaClock, FaNetworkWired } from 'react-icons/fa';


const Pricing = () => {
  const [currency, setCurrency] = useState('₹ INR');
  const [price, setPrice] = useState(99 + '/user/month');

  const handleCurrencyChange = (selectedCurrency) => {
    setCurrency(selectedCurrency);
    switch (selectedCurrency) {
      case '€ EUR':
        setPrice((99 / 89).toFixed(2) + '/user/month');
        break;
      case '£ GBP':
        setPrice((99 / 102).toFixed(2) + '/user/month');
        break;
      case '$ USD':
        setPrice((99 / 82).toFixed(2) + '/user/month');
        break;
      case '₹ INR':
        setPrice(99 + '/user/month');
        break;
      default:
        setPrice(99 + '/user/month');
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-white text-gray-900 py-16">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h1 className="text-3xl md:text-5xl font-bold mb-4">
            Boost your <span className="text-blue-700">cyber resilience</span> and secure your company's future
          </h1>
          <p className="text-lg md:text-xl mb-5 mt-5">
            Start with a Free Demo and Discover Our Advanced Cyber Resilience Solutions Before Choosing a Plan.
          </p>
          <p className="text-2xl font-semibold mb-6">
            Starting at {currency} {price}
          </p>
          <div className="flex justify-center space-x-4 mb-12">
            <button
              onClick={() => handleCurrencyChange('₹ INR')}
              className={`px-4 py-2 border rounded-md ${currency === '₹ INR' ? 'border-blue-700 bg-blue-700 text-white' : 'border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-white'}`}
            >
              ₹ INR
            </button>
            <button
              onClick={() => handleCurrencyChange('€ EUR')}
              className={`px-4 py-2 border rounded-md ${currency === '€ EUR' ? 'border-blue-700 bg-blue-700 text-white' : 'border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-white'}`}
            >
              € EUR
            </button>
            <button
              onClick={() => handleCurrencyChange('£ GBP')}
              className={`px-4 py-2 border rounded-md ${currency === '£ GBP' ? 'border-blue-700 bg-blue-700 text-white' : 'border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-white'}`}
            >
              £ GBP
            </button>
            <button
              onClick={() => handleCurrencyChange('$ USD')}
              className={`px-4 py-2 border rounded-md ${currency === '$ USD' ? 'border-blue-700 bg-blue-700 text-white' : 'border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-white'}`}
            >
              $ USD
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8 shadow-lg">
            {/* Advanced Cyber Resilience Platform */}
            <div className="bg-neomorphism-gradient p-6 rounded-lg shadow-neomorphism border border-transparent">
              <h2 className="text-2xl font-semibold mb-4">ADVANCED CYBER RESILIENCE PLATFORM</h2>
              <p className="mb-6 text-center">
                Get access to our core platform.
              </p>
              <h3 className="text-xl font-semibold mb-4">AREAS OF FOCUS:</h3>
              <ul className="flex flex-wrap justify-center space-x-6 space-y-2 text-lg">
                <li className="flex items-center">
                  <FaCalendarAlt className="inline text-blue-700 mr-2" />
                  Unlimited Campaigns
                </li>
                <li className="flex items-center">
                  <FaChartLine className="inline text-blue-700 mr-2" />
                  User Behavioral Analytics
                </li>
                <li className="flex items-center">
                  <FaFileAlt className="inline text-blue-700 mr-2" />
                  Standard Report
                </li>
                <li className="flex items-center">
                  <FaBook className="inline text-blue-700 mr-2" />
                  Awareness Content
                </li>
                <li className="flex items-center">
                  <FaPhone className="inline text-blue-700 mr-2" />
                  Free On-Call Support: Mon-Fri
                </li>
                <li className="flex items-center">
                  <FaClock className="inline text-blue-700 mr-2" />
                  Scheduler Randomization
                </li>
                <li className="flex items-center">
                  <FaNetworkWired className="inline text-blue-700 mr-2" />
                  LMS Integration
                </li>
              </ul>
            </div>


          </div>
        </div>
        {/* Phishing Simulator Section */}
        <div className="bg-white text-gray-900 py-16 mt-12">
          <div className="max-w-6xl mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Master Phishing Prevention with <span className="text-blue-700">Phishinstinct</span>
            </h2>
            <p className="text-lg md:text-xl mb-8 text-justify">
              Elevate your organization's security posture by leveraging cutting-edge, phishing simulations. Equip your team with the skills they need to identify and respond to phishing attacks, significantly reducing the risk of cyber threats and data breaches.
            </p>
            <div className="flex justify-center">
              <div className="bg-white rounded-lg p-8 md:w-2/3 lg:w-1/2">
                <Link to="/bookdemo">
                  <button className="bg-blue-700 text-white py-2 px-4 rounded-md mt-4 hover:bg-blue-800 transition-colors">
                    GET STARTED NOW!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
